export default {
  login: {
    username: null,
    password: null,
  },
  register: {
    identifier: null,
    role: null,
    username: null,
    password: null,
  },
  catatankriminal:{
    judul: null,
    pasal: null,
    description: null,
    urlimg: null
  },
  catatankriminalKendaraan:{
    plate: null,
    judul: null,
    pasal: null,
    description: null
  },
  lisensi:{
    addon_tipe_sim: [],
    steam_name: null,
    nomor: null,
    type: null,
    start: null,
    end: null,
    imgurl: null
  },
  stnk:{
    steam_name: null,
    plate: null,
    start: null,
    end: null,
    warna: null,
  },
  dpo:{
    image: null,
    title: null,
    description: null,
    release_reason: null,
    start: null,
    end: null
  },
  dpk:{
    image: null,
    title: null,
    description: null,
    start: null,
    end: null
  },
  absen:{
    id: null,
    start: null,
    end: null
  },
  kasus_berjalan:{
    name: null,
    kronologi: null,
    penyidik: null,
    penanggungjawab: null,
  },
  progress_kasus_berjalan:{
    id_kasus_berjalan: null,
    description: null,
    image_url: null,
  },
  progress_penanganan:{
    id_penanganan: null,
    suspect: null,
    loker: null,
    description: null,
    image_url: null,
  },
  penanganan:{
    id_penanganan: null,
    komando: null,
    negosiator1: null,
    negosiator2: null,
    loker_id: null,
    loker_no: null
  },
  master_user:{
    identifier: null,
    role: null,
    username: null,
    password: null
  },
  master_news:{
    title: null,
    content: null,
    status: null
  },
  master_pasal:{
    type: null,
    pasal: null,
    name: null,
    jail: null,
    denda: null
  },
  sita_form:{
    officer: null,
    identifier: null,
    plate: null,
    price: null
  },
  akses_form:{
    identifier: null,
    akses: null,
  },
  config_form:{
    id: null,
    role: null,
  },
  discord_webhook:{
    id: null,
    url: null,
  }
}
